<template>
  <div>
    <div class="flex">
      <div class="section-title">
        {{ $t('pages.vouchers.edit.form.constraints.title') }}
      </div>
      <th-popover
        :text="$t('pages.vouchers.edit.form.constraints.enabled.popover')"
        placement="bottom"
      />
    </div>

    <div class="row">
      <el-switch v-model="enabled" />
      <p class="row-text label">
        {{ $t('pages.vouchers.edit.form.constraints.enabled.label') }}
      </p>
    </div>

    <div class="weekday-title label">
      {{ $t('pages.vouchers.edit.form.constraints.weekdays.label') }}
    </div>
    <div class="weekdays-checkboxes">
      <div>
        <el-checkbox v-model="monday">
          {{ $t('pages.vouchers.edit.form.constraints.monday.label') }}
        </el-checkbox>
      </div>
      <div>
        <el-checkbox v-model="tuesday">
          {{ $t('pages.vouchers.edit.form.constraints.tuesday.label') }}
        </el-checkbox>
      </div>
      <div>
        <el-checkbox v-model="wednesday">
          {{ $t('pages.vouchers.edit.form.constraints.wednesday.label') }}
        </el-checkbox>
      </div>
      <div>
        <el-checkbox v-model="thursday">
          {{ $t('pages.vouchers.edit.form.constraints.thursday.label') }}
        </el-checkbox>
      </div>
      <div>
        <el-checkbox v-model="friday">
          {{ $t('pages.vouchers.edit.form.constraints.friday.label') }}
        </el-checkbox>
      </div>
      <div>
        <el-checkbox v-model="saturday">
          {{ $t('pages.vouchers.edit.form.constraints.saturday.label') }}
        </el-checkbox>
      </div>
      <div>
        <el-checkbox v-model="sunday">
          {{ $t('pages.vouchers.edit.form.constraints.sunday.label') }}
        </el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'

export default {
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    enabled: {
      get() {
        return safeGet(this.modelValue, 'time.scheduled.day_of_week.enabled')
      },
      set(value) {
        const constraints = this.$deepClone(this.modelValue)

        safeSet(constraints, 'time.scheduled.day_of_week.enabled', value)
        this.$emit('constraints-updated', constraints)
      }
    },
    monday: {
      get() {
        return this.isWeekdayEnabled('monday')
      },
      set(val) {
        this.enableWeekDay('monday', val)
      }
    },
    tuesday: {
      get() {
        return this.isWeekdayEnabled('tuesday')
      },
      set(val) {
        this.enableWeekDay('tuesday', val)
      }
    },
    wednesday: {
      get() {
        return this.isWeekdayEnabled('wednesday')
      },
      set(val) {
        this.enableWeekDay('wednesday', val)
      }
    },
    thursday: {
      get() {
        return this.isWeekdayEnabled('thursday')
      },
      set(val) {
        this.enableWeekDay('thursday', val)
      }
    },
    friday: {
      get() {
        return this.isWeekdayEnabled('friday')
      },
      set(val) {
        this.enableWeekDay('friday', val)
      }
    },
    saturday: {
      get() {
        return this.isWeekdayEnabled('saturday')
      },
      set(val) {
        this.enableWeekDay('saturday', val)
      }
    },
    sunday: {
      get() {
        return this.isWeekdayEnabled('sunday')
      },
      set(val) {
        this.enableWeekDay('sunday', val)
      }
    }
  },
  created() {
    if (!this.modelValue) this.$emit('constraints-updated', {})
  },
  methods: {
    isWeekdayEnabled(weekday) {
      const slots =
        safeGet(
          this.modelValue,
          `time.scheduled.day_of_week.${weekday}.slots`
        ) || []
      const slot = slots.find((slot) => !slot.start && !slot.end)
      return slot ? slot.enabled : false
    },
    enableWeekDay(weekday, value) {
      // find the index of the correct time slot
      const slots =
        safeGet(
          this.modelValue,
          `time.scheduled.day_of_week.${weekday}.slots`
        ) || []
      const index = slots.findIndex((slot) => !slot.start && !slot.end)

      const newSlots = [...slots]

      // if it exists, then update
      // replace complete object to sever pointer to old object
      if (index !== -1) {
        newSlots[index] = {
          enabled: value,
          start: null,
          end: null
        }
      } else {
        // otherwise push a new slot
        newSlots.push({
          enabled: true,
          start: null,
          end: null
        })
      }

      const constraints = this.$deepClone(this.modelValue) || {}

      safeSet(
        constraints,
        `time.scheduled.day_of_week.${weekday}.slots`,
        newSlots
      )
      this.$emit('constraints-updated', constraints)
    }
  }
}
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.section-title {
  padding-right: 1rem;
}

.row {
  display: flex;
  align-items: center;
}

.row-text {
  padding: 0 1rem;
}

.weekday-title {
  margin-bottom: 10px;
}

.weekdays-checkboxes {
  margin-left: 10px;
}

.label {
  font-size: 14px;
  color: var(--label-text-color);
}
</style>
